@import url('https://www.w3schools.com/w3css/4/w3.css');
@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://use.fontawesome.com/releases/v5.8.1/css/all.css');
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css');
@import url('bootstrap/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
/* @import url('https://stackpath.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.bundle.min.js'); */

.card-image {
  height: 100px; /* Adjust the height to your desired value */
  width: 100px; /* Adjust the width to your desired value */
  object-fit: cover; /* Maintain aspect ratio and fit within the container */
}


.card-img-top {
  height: 300px; /* Adjust the height to your desired value */
  object-fit: cover; /* This ensures the images maintain their aspect ratio */
}

#slider-text{
    padding-top: 40px;
    display: block;
  }
  #slider-text .col-md-6{
    overflow: hidden;
  }
  
  #slider-text h2 {
    font-family: Poppins;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 3px;
    margin: 30px auto;
    padding-left: 40px;
  }
  #slider-text h2::after{
    border-top: 2px solid #c7c7c7;
    content: "";
    position: absolute;
    bottom: 35px;
    width: 100%;
    }
  
  #itemslider h4{
    font-family: Poppins;
    font-weight: 400;
    font-size: 12px;
    margin: 10px auto 3px;
  }
  #itemslider h5{
    font-family: Poppins;
    font-weight: bold;
    font-size: 12px;
    margin: 3px auto 2px;
  }
  #itemslider h6{
    font-family: Poppins;
    font-weight: 300;;
    font-size: 10px;
    margin: 2px auto 5px;
  }
  .badge {
    background: #b20c0c;
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    line-height: 31px;
    font-family: Poppins;
    font-weight: 300;
    font-size: 14px;
    border: 2px solid #FFF;
    box-shadow: 0 0 0 1px #b20c0c;
    top: 5px;
    right: 25%;
  }
  #slider-control img{
    padding-top: 60%;
    margin: 0 auto;
  }
  @media screen and (max-width: 992px){
  #slider-control img {
    padding-top: 70px;
    margin: 0 auto;
  }
  }
  
.testimonial-card .card-up {
  height: 120px;
  overflow: hidden;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.testimonial-card .avatar {
  width: 110px;
  margin-top: -60px;
  overflow: hidden;
  border: 3px solid #fff;
  border-radius: 50%;
}
body.body-color{
  margin: 0;
  padding: 0;
  font-family: Poppins;
  background:#f4f4f5
}

/* body.body-color a{color:black ;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
} */
body.body-color a#navbar{
  color:black ;
  /* font-family: sans-serif; */
  font-size: 17px;
  font-weight: bold;
  text-decoration: none;
}

body.body-color li#navbar{
  color:black;
  font-size:17px;
}

body.body-dark li#navbar{
  color:white
}
body.body-color li#navbar:hover{
  color:#00df9a
}
body.body-dark li#navbar:hover{
  color:#00df9a
}
/* gray-975 */
body.body-dark{
  background-color: #0a0a0a;
}
/* gray-950 */
/* body.body-dark{
  background-color: #101010;
} */
header body.body-color h1 {
  color: #000;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

header body.body-dark h1 {
  color: #eee;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

/* 
header h1 {
  color: #eee;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
} */

body.body-color h2 {
  color: black;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: Poppins;
}

body.body-dark h2 {
  color: white;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: Poppins;
}

.quantity-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px; /* Adjust width as needed */
}

.quantity-field button {
  padding: 5px;
  border: none;
  cursor: pointer;
}

.quantity-field button {
  padding: 5px;
  border: none;
  cursor: pointer;
}

/* Media query for screens smaller than 600px */
@media (max-width: 600px) {
  body.body-color h2,
  body.body-dark h2 {
    font-size: 20px; /* Adjust font size for smaller screens */
  }
}

/* Media query for screens smaller than 400px */
@media (max-width: 400px) {
  body.body-color h2,
  body.body-dark h2 {
    font-size: 18px; /* Adjust font size for even smaller screens */
  }
}

body.body-color h5{
  color: black;
  font-family: Poppins;
}

body.body-dark h5{
  color: white;
  font-family: Poppins;

}

body.body-color div#caseStudyContainer{
  color: white;
}

body.body-dark div#caseStudyContainer{
  color: white;
}

body.body-color span#card_subtitle{
  color: #1a1a1a;
}

body.body-dark span#card_subtitle{
  color: #333;
}

body.body-color span#about{
  color: black;
}

body.body-dark span#about{
  color: white;
}

body.body-dark a#navbar {
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
}
body.body-dark h1#navbar{
  color:rgb(255, 255, 255);
}
body.body-color h1#navbar{
  color:rgb(0, 0, 0);
}
body.body-dark a#navbar:hover{
  text-decoration: none;
    color: #00df9a;
}
body.body-color a#navbar:hover{
  text-decoration: none;
    color: #00df9a;
}

body.body-color p#typed{
  color:black;
}
body.body-dark p#typed{
  color:#EDF2F7;
}
body.body-color h1#rkbs{
  color:black
}
body.body-dark h1#rkbs{
  color:#F5F5F5
}
/* body.body-color #rkbs1 {
  color: black;
}
body.body-dark #rkbs1 {
  color: white;
} */

a{
  color: #9E9E9E;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;}
  
.card{
  margin-bottom:20px;
  border:none;
}

.box {
  width: 500px;
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #191919;
  text-align: center;
  transition: 0.25s;
  margin-top: 100px
}

.box input[type="text"],
.box input[type="password"] {
  border: 0;
  background: none;
  display: block;
  margin: 20px auto;
  text-align: center;
  border: 2px solid #2971ac;
  /* border: 2px solid #b72020; */
  padding: 10px 10px;
  width: 250px;
  outline: none;
  color: white;
  border-radius: 24px;
  transition: 0.25s
  /* position: relative; */
}

.box h1 {
  color: white;
  text-transform: uppercase;
  font-weight: 500
}

.box input[type="text"]:focus,
.box input[type="password"]:focus {
  width: 300px;
  /* border-color: #2971ac */
}

.box input[type="submit"] {
  border: 0;
  background: none;
  display: block;
  margin: 20px auto;
  text-align: center;
  border: 2px solid #2ecc71;
  /* border: 2px solid #b72020; */
  padding: 14px 40px;
  outline: none;
  color: white;
  border-radius: 24px;
  transition: 0.25s;
  cursor: pointer
}

.box input[type="submit"]:hover {
  background: #2ecc71
  /* background: #b72020 */
}

.forgot {
  text-decoration: underline
}

/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Montserrat-Regular;
  src: url('../Contact/fonts/montserrat/Montserrat-Regular.ttf'); 
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('../Contact/fonts/montserrat/Montserrat-Bold.ttf'); 
}

@font-face {
  font-family: Montserrat-ExtraBold;
  src: url('../Contact/fonts/montserrat/Montserrat-ExtraBold.ttf'); 
}

@font-face {
  font-family: Montserrat-Medium;
  src: url('../Contact/fonts/montserrat/Montserrat-Medium.ttf'); 
}



/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
    margin: 0px; 
    padding: 0px; 
    box-sizing: border-box;
}

body, html {
    height: 100%;
    font-family: Poppins;
}

a:hover {
    text-decoration: none;
    color: #00df9a;
}

/*---------------------------------------------*/
h1,h2,h3,h4,h5,h6 {
    margin: 0px;
}

/* body.body-color span{
  color:black;
  font-family: 'Poppins', sans-serif;

} */
body.body-dark span#case_study{
  color:white ;
  font-family: 'Poppins', sans-serif;
}
body.body-dark dt,
body.body-dark dd {
  color: white;
  font-family: 'Poppins', sans-serif;

}

body.body-color dt,
body.body-color dd {
  color: black;
  font-family: 'Poppins', sans-serif;
}

body.body-dark h6{
  color:white ;

  font-family: 'Poppins', sans-serif;
}

body.body-color h6{
  color:black;
  font-family: 'Poppins', sans-serif;

}

body.body-color p#demo{
  color:black;
  font-family: 'Poppins', sans-serif;

}

body.body-dark p#demo{
  color:white;
  font-family: 'Poppins', sans-serif;
}

body.body-dark i#solutions{
  color:white;
  font-family: 'Poppins', sans-serif;

}

body.body-color i#solutions{
  color:black;
  font-family: 'Poppins', sans-serif;

}
body.body-color span#solutions{
  color:black;
  font-family: 'Poppins', sans-serif;

}
body.body-dark span#solutions{
  color:white;
  font-family: 'Poppins', sans-serif;

}
body.body-dark p#solutions{
  color:white ;
  font-family: 'Poppins', sans-serif;

}
body.body-color p#solutions{
  color:black ;
  font-family: 'Poppins', sans-serif;

}

ul, li {
    margin: 0px;
    list-style-type: none;
}

input {
    outline: none;
    border: none;
}

textarea {
  outline: none;
  border: none;
}

button {
    outline: none !important;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

iframe {
    border: none !important;
}

/*[ Contact 1 ]*/

.contact1 {
  width: 100%;
  min-height: 100%;
  padding: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.body-color .container-contact1 {
  width: 1163px;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  padding: 90px 130px 88px 148px;
}

.body-dark .container-contact1 {
  width: 1163px;
  background: #333333;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  padding: 90px 130px 88px 148px;
}

.contact1-pic {
  width: 296px;
}

.contact1-pic img {
  max-width: 100%;
}

.contact1-form {
  width: 390px;
}

.contact1-form-title {
  display: block;
  font-family: Montserrat-ExtraBold;
  font-size: 24px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 44px;
}

input.input1 {
  height: 50px;
  border-radius: 25px;
  padding: 0 30px;
}
input.input1 + .shadow-input1 {
  border-radius: 25px;
}

textarea.input1 {
  min-height: 150px;
  border-radius: 25px;
  padding: 12px 30px;
}
textarea.input1 + .shadow-input1 {
  border-radius: 25px;
}

.wrap-input1 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 20px;
}

.input1 {
  display: block;
  width: 100%;
  background: #e6e6e6;
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #666666;
}

.shadow-input1 {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: rgba(87,184,70, 0.5);
}

.input1:focus + .shadow-input1 {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}

/*---------------------------------------------*/
.container-contact1-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contact1-form-btn {
  min-width: 193px;
  height: 50px;
  border-radius: 25px;
  background: #00df9a;
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.carrer-form-btn {
  min-width: 193px;
  height: 50px;
  border-radius: 25px;
  background: #205c82;
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.carrer-form-btn:hover {
  background: #2693c5;
}

.contact1-form-btn i {
  margin-left: 7px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.contact1-form-btn:hover {
  background: #333333;
}

.contact1-form-btn:hover i {
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -o-transform: translateX(10px);
  transform: translateX(10px);
}




/*[ Responsive ]*/

@media (max-width: 1200px) {
  .contact1-pic {
    width: 33.5%;
  }

  .contact1-form {
    width: 44%;
  }
}

@media (max-width: 992px) {
  .container-contact1 {
    padding: 90px 80px 88px 90px;
  }

  .contact1-pic {
    width: 35%;
  }

  .contact1-form {
    width: 55%;
  }
}

@media (max-width: 768px) {
  .container-contact1 {
    padding: 90px 80px 88px 80px;
  }

  .contact1-pic {
    display: none;
  }

  .contact1-form {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .container-contact1 {
    padding: 90px 15px 88px 15px;
  }
}


/*[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: white;
  border: 1px solid #c80000;
  border-radius: 13px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
  pointer-events: none;

  font-family: Montserrat-Medium;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 13px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

/* Social Networks CSS */

.fa-youtube {
  font-size: 25px; /* Adjust the font size to your desired value */
}
.fa-facebook-f {
  font-size: 25px; /* Adjust the font size to your desired value */
}
.fa-linkedin{
  font-size: 25px; /* Adjust the font size to your desired value */
}

ul.social-network {
  list-style: none;
  display: inline;
  margin-left: 0 !important;
  padding: 0
}

ul.social-network li {
  display: inline;
  margin: 0 5px
}

.social-network a.icoFacebook:hover {
  background-color: #3B5998
}

.social-network a.icoTwitter:hover {
  background-color: #33ccff
}

.social-network a.icoGoogle:hover {
  background-color: #ff0000
}

.social-network a.icoFacebook:hover i,
.social-network a.icoTwitter:hover i,
.social-network a.icoGoogle:hover i {
  color: #fff
}

a.socialIcon:hover,
.socialHoverClass {
  color: #44BCDD
}

.social-circle li a {
  display: inline-block;
  position: relative;
  margin: 0 auto 0 auto;
  border-radius: 50%;
  text-align: center;
  width: 50px;
  height: 50px;
  font-size: 20px
}

.social-circle li i {
  margin: 0;
  line-height: 50px;
  text-align: center
}

.social-circle li a:hover i,
.triggeredHover {
  transform: rotate(360deg);
  transition: all 0.2s
}

.social-circle i {
  transition: all 0.8s;
  transition: all 0.8s
}



.dropdown-menu {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-60%, 100%);
}

.dropdown-menu {
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-container-1{
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden; /* Hide the scroll bar */
  animation: scroll-loop 10s linear infinite; /* Use the "scroll-loop" animation for auto-scrolling */
  animation-direction:infinite;
  width: calc(100px*12); /* Make sure the container takes up the full width of the parent */
}

@keyframes scroll-loop {
  0% {
    transform: translateX(0); /* Start from the initial position */
  }
  100% {
    transform: translateX(calc(-250px *7)); /* Move to the left to simulate scrolling */
  }
}

.card-1 {
  flex-shrink: 0; /* Ensure the cards won't shrink and maintain their size */
  animation: scroll-loop 20s linear infinite;
  animation-play-state: running;
}

.nav-link {
  color: #9E9E9E;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
}

.nav-link.text-dark {
  color: #fff;
}

@media (prefers-color-scheme: dark) {
  .nav-link {
    color: #fff;
  }

  .nav-link.text-dark {
    color: #9E9E9E;
  }
}

/* Accordion STyling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  background: #eee;
}

header {
  display: flex;
  justify-content: center;
  padding: 15px;
  background: #3c3c3c;
  border-bottom: 3px solid #56e388;
}



.faqs {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 15px;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.wrapper{
  padding: 30px;
  max-width: 1200px;
  margin: auto;
}
.h3{
  font-weight: 900;
}
.views{
  font-size: 0.85rem;
}

.green-label{
  background-color: #defadb;
  color: #48b83e;
  border-radius: 5px;
  font-size: 0.8rem;
  margin: 0 3px;
}
.radio,.checkbox{
  padding: 6px 10px;
}
.border{
  border-radius: 12px;
}
.options{
  position: relative;
  padding-left: 25px;
}
.radio label,
.checkbox label{
  display: block;
  font-size: 14px;
  cursor: pointer;
  margin: 0;
}
.options input{
  opacity: 0;
}
.checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 50%;
}
.options input:checked ~ .checkmark:after {
  display: block;
}
.options .checkmark:after{
  content: "";
width: 9px;
  height: 9px;
  display: block;
background: white;
  position: absolute;
  top: 52%;
left: 51%;
  border-radius: 50%;
  transform: translate(-50%,-50%) scale(0);
  transition: 300ms ease-in-out 0s;
}
.options input[type="radio"]:checked ~ .checkmark{
  background: #61b15a;
  transition: 300ms ease-in-out 0s;
}
.options input[type="radio"]:checked ~ .checkmark:after{
  transform: translate(-50%,-50%) scale(1);
}
.count{
  font-size: 0.8rem;
}
label{
  cursor: pointer;
}
.tick{
  display: block;
  position: relative;
  padding-left: 23px;
  cursor: pointer;
  font-size: 0.8rem;
  margin: 0;
}
.tick input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.check{
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.tick:hover input ~ .check {
  background-color: #f3f3f3;
}
.tick input:checked ~ .check {
  background-color: #61b15a;
}
.check:after {
  content: "";
  position: absolute;
  display: none;
}
.tick input:checked ~ .check:after {
  display: block;
  transform: rotate(45deg) scale(1);
} 
.tick .check:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg) scale(2);
}
#country{
  font-size: 0.8rem;
  border: none;
  border-left: 1px solid #ccc;
  padding: 0px 10px;
  outline: none;
  font-weight: 900;
}
.close{
  font-size: 1.2rem;
}
div.text-muted{
  font-size: 0.85rem;
}
#sidebar{
  width: 25%;
  float: left;
}
.category{
  font-size: 0.9rem;
  cursor: pointer;
}
.list-group-item{
  border: none;
  padding: 0.3rem 0.4rem 0.3rem 0rem;
}
.badge-primary{
  background-color: #defadb;
  color: #48b83e
}
.brand .check{
  background-color: #fff;
  top: 3px;
  border: 1px solid #666;
}
.brand .tick{
  font-size: 1rem;
  padding-left: 25px;
}
.rating .check{
  background-color: #fff;
  border: 1px solid #666;
  top: 0;
}
.rating .tick{
  font-size: 0.9rem;
  padding-left: 25px;
}
.rating .fas.fa-star{
  color: #ffbb00;
  padding: 0px 3px;
}
.brand .tick:hover input ~ .check,
.rating .tick:hover input ~ .check{
  background-color: #f9f9f9;
}
.brand .tick input:checked ~ .check,
.rating .tick input:checked ~ .check{
  background-color: #61b15a;
}
#products{
  width: 75%;
  padding-left: 30px;
  margin: 0;
  float: right;
}

.card-body{
  padding: 0.5rem;
}

.card-body .description{
  font-size: 0.78rem;
  padding-bottom: 8px;
}
div.h6,h6{
  margin: 0;
}
.product .fa-star{
  font-size: 0.9rem;
}
.rebate{
  font-size: 0.9rem   ;
}
/* .btn.btn-primary{
  background-color: #48b83e;
  color: #fff;
  border: 1px solid #008000;    
  border-radius: 10px;
  font-weight: 800;
}
.btn.btn-primary:hover{
  background-color: #48b83ee8;
} */
/* img{
  width: 192px;
  height: 132px;
  object-fit: contain;
} */

.clear{
  clear: both;
}
.btn.btn-success{
  visibility: hidden;
}
/* @media(min-width:992px){
  .filter,#mobile-filter{
      display: none;
  }
}
@media(min-width:768px) and (max-width:991px){
  .radio, .checkbox {
      padding: 6px 10px;
      width: 49%;
      float: left;
      margin: 5px 5px 5px 0px;
  }
  .filter,#mobile-filter{
      display: none;
  }
}
@media(min-width:576px) and (max-width:767px){
  #sidebar{
      width: 35%;
  }
  #products{
      width: 65%;
  }
  .filter,#mobile-filter{
      display: none;
  }
  .h3 + .ml-auto{
      margin: 0;
  }
}
@media(max-width:575px){
  .wrapper{
      padding: 10px;
  }
  .h3{
      font-size: 1.3rem;
  }
  #sidebar{
      display: none;
  }
  #products{
      width: 100%;
      float: none;
  }
  #products{
      padding: 0;
  }
  .clear{
      float: left;
      width: 80%;
  }
  .btn.btn-success{
      visibility: visible;
      margin: 10px 0px;
      color: #fff;
      padding: 0.2rem 0.1rem;
      width: 20%;
  } 
  .green-label{
      width: 50%;
  }
  .btn.text-success{
      padding: 0;
  }
  .content,#mobile-filter{
      clear: both;
  }
} */

body.body-color label{
  color: #1a1a1a;
}
body.body-dark label{
  color: #ffffff;
}
body.body-color label#quote{
  color: #1a1a1a ;
  font-size: medium;
}
body.body-dark label#quote{
  color: #1a1a1a ;
  font-size: medium;
}


/* Always set the map height explicitly to define the size of the div
    * element that contains the map. */
    #map {
      height: 100%;
    }
    
    h1#contactus {
      font-size: 50px;
      text-align: center;
      padding-top: 10px;
      margin-left: -9px;
      color: #d59563;
    }
    
    /* Heading Shake Effect */
    h1:hover#contactus {
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
    
    @keyframes shake {
      10%, 90% {
          transform: translate3d(-1px, 0, 0);
      }
      20%, 80% {
          transform: translate3d(2px, 0, 0);
      }
      30%, 50%, 70% {
          transform: translate3d(-4px, 0, 0);
      }
      40%, 60% {
          transform: translate3d(4px, 0, 0);
      }
    }
    
    h6#contactus {
      text-align: center;
      color: #d59563;
    }
    
    #left {
      float: left;
      width: 50%;
      height: 700px;
    }
    
    #right {
      display: inline-block;
      position: absolute;
      width: 50%;
      height: 700px;
    }
    
    #cleared {
      clear: both;
    }
    
    ul {
      list-style-type: none;
    }
    
    /* form {
      max-width: 420px;
      margin: 20px auto;
      padding: 0; 
    } */
    
    .formbox {
      background: transparent;
      padding: 10px;
      max-width: 500px;
      margin: 10px auto;
      height:75%;
      border-radius: 1px;
      box-shadow: 0 4px 10px 4px transparent;
    }
    
    /* form {
      display: inline-block;
    } */

    /* body.body-dark input.short{
      color:white
    } */
    
    body.body-dark input.short {
      width: 80%;
      color: white;
      font-family: Poppins;
      font-weight: 500;
      font-size: 18px;
      border-radius: 5px;
      line-height: 22px;
      background-color: transparent;
      border: 2px solid #d59563;
      transition: all 0.3s;
      padding: 13px;
      margin-right: 6px;
      margin-bottom: 15px;
      box-sizing: border-box;
      outline: 0;
    }

    /* body.body-color input.short:focus {
      color: black;
    } */

    body.body-color input.short {
      width: 80%;
      color: black;
      font-family: Poppins;
      font-weight: 500;
      font-size: 18px;
      border-radius: 5px;
      line-height: 22px;
      background-color: transparent;
      border: 2px solid #d59563;
      transition: all 0.3s;
      padding: 13px;
      margin-right: 6px;
      margin-bottom: 15px;
      box-sizing: border-box;
      outline: 0;
    }

    body.body-color .feedback-input {
      color: black;
      font-family: Poppins;
      font-weight: 500;
      font-size: 18px;
      border-radius: 5px;
      line-height: 22px;
      background-color: transparent;
      border: 2px solid #d59563;
      transition: all 0.3s;
      padding: 13px;
      margin-bottom: 15px;
      width: 120%;
      box-sizing: border-box;
      outline: 0;
    }

    body.body-dark .feedback-input {
      color: white;
      font-family: Poppins;
      font-weight: 500;
      font-size: 18px;
      border-radius: 5px;
      line-height: 22px;
      background-color: transparent;
      border: 2px solid #d59563;
      transition: all 0.3s;
      padding: 13px;
      margin-bottom: 15px;
      width: 120%;
      box-sizing: border-box;
      outline: 0;
    }
    
    #map-overlap {
      top: 10px;
      left: 10px;
      margin-top: -650px;
      margin-left: 50px;
      text-align: left;
      font-size: 17px;
      padding-top: 15px;
      padding-left: 15px;
      width: 205px;
      height: 155px;
      z-index: 10;
      font-family: Poppins;
      font-weight: 200;
      position: relative;
      background: rgb(30, 30, 40);
      color: white;
    }
    
    #at {
      color: #d59563;
    }
    
    span#contactus {
      text-align: right;
      font-family: Poppins;
      font-weight: 200;
    }

    span#typed{
      color:#00df9a
    }
    
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: dimgrey;
    }
    
   
    
    .feedback-input:focus {
      border: 2px solid #d59563;
    }
    
    textarea#contactus {
      height: 150px;
      line-height: 150%;
      resize: vertical;
    }
    
    [type="submit"] {
      font-family: Poppins;
      width: 20%;
      background: rgb(30, 30, 40);
      border-radius: 5px;
      cursor: pointer;
      color: #d59563;
      font-size: 16px;
      margin-left: 80%;
      padding-top: 10px;
      padding-bottom: 10px;
      transition: all 0.3s;
      margin-top: -4px;
      border: 2px solid #d59563;
      font-weight: 500;
    }
    
    [type="submit"]:hover#contactus {
      background: #d59563;
      color: rgb(30, 30, 40);
      outline: 0 !important;
    }
    
    @media all and (max-width: 800px) {
      #left, #right {
        display: block;
        float: none;
        width: 100%;
      }
    }
    
    @media all and (max-width: 800px) {
      .short {
        min-width: 99%;
      }
    }

    
  

